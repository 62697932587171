import { CartAddonsState, CartAddonsAction } from './types';

const initialState: CartAddonsState = {
  forYou: {
    id: 0,
    fetching: false,
    algorithm: 'forYou',
    title: 'Gifts Under $50',
    heading: '',
    products: [],
    type: 'product-slider'
  },
  default: {
    id: 1,
    fetching: false,
    algorithm: 'default',
    title: 'Holiday Favorites',
    heading: '',
    products: [],
    type: 'product-slider'
  },
  giftCards: {
    id: 2,
    title: 'Virtual Gift Cards',
    heading: 'A Gift Guaranteed to Fit',
    type: 'gift-card'
  }
};

const reducer = (
  state: CartAddonsState = initialState,
  action: CartAddonsAction
) => {
  switch (action.type) {
    case 'CART_ADDONS/GET_CART_ADDON_PRODUCTS': {
      return {
        ...state,
        [action.algorithm]: {
          ...state[action.algorithm],
          fetching: true
        }
      };
    }
    case 'CART_ADDONS/GET_CART_ADDON_PRODUCTS_SUCCESS':
      return {
        ...state,
        [action.algorithm]: {
          ...state[action.algorithm],
          products: action.addonProducts
        }
      };
    case 'CART_ADDONS/GET_CART_ADDON_PRODUCTS_FAILURE': {
      return {
        ...state
      };
    }
    // Currently unused, to use simply pass `googleRecommendedProducts` as the algorithm
    // case 'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS_SUCCESS':
    //   return {
    //     ...state,
    //     googleRecommended: {
    //       ...state.googleRecommendedProducts,
    //       products: action.googleRecommendedProducts
    //     }
    //   }
    default:
      return state;
  }
};

export default reducer;
