import { GiftCardsStateType, GiftCardActionsType } from './types';

const initialState: GiftCardsStateType = {
  giftCardVariants: [],
  error: '',
  fetchingVariants: false,
  success: false
};

const reducer = (
  state: GiftCardsStateType = initialState,
  action: GiftCardActionsType
) => {
  switch (action.type) {
    case 'GIFT_CARD_VARIANTS/FETCH':
      return { ...state, fetchingVariants: true, error: '' };

    case 'GIFT_CARD_VARIANTS/FETCH_SUCCESS':
      return {
        ...state,
        giftCardVariants: [...action.variants],
        error: '',
        fetchingVariants: false
      };
    case 'GIFT_CARD/CREATE_SUCCESS':
      return { ...state, error: '', success: true };

    case 'GIFT_CARD_VARIANTS/FETCH_FAILURE':
    case 'GIFT_CARD/CREATE_FAILURE':
      return {
        ...state,
        error: action.error,
        success: false,
        fetchingVariants: false
      };
    default:
      return state;
  }
};

export default reducer;
