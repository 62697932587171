// @flow

import type { SearchResultsActionState, SearchResultsState } from './types';
import toggleFilter from 'modules/page/utils/toggleFilter';
import sortFilters from 'modules/page/utils/sortFilters';

const initialState = {
  brands: [],
  pages: [],
  products: [],
  journalPosts: [],
  loadingResults: true,
  loadingMoreProducts: false,
  hasResults: true,
  meta: {
    page: 1,
    totalPages: 0,
    totalResults: 0
  },
  queryId: '',
  indexName: '',
  facets: {
    category: [],
    brand: [],
    clearance: [],
    color: [],
    priceBuckets: [],
    sizes: [],
    inseam: [],
    fit: [],
    stretch: [],
    pattern: [],
    wash: [],
    fabric: [],
    performanceCapabilities: [],
    countryOfOrigin: [],
    exclusive: [],
    bestseller: [],
    soleShape: [],
    upperMaterial: [],
    finalSale: [],
    lined: [],
    weight: [],
    plaidType: [],
    printType: [],
    gift: []
  },
  filtersAreApplied: false,
  filtersActive: {
    category: [],
    brand: [],
    clearance: [],
    color: [],
    sizes: [],
    inseam: [],
    prices: [],
    fit: [],
    stretch: [],
    pattern: [],
    wash: [],
    fabric: [],
    performanceCapabilities: [],
    countryOfOrigin: [],
    exclusive: [],
    bestseller: [],
    soleShape: [],
    upperMaterial: [],
    finalSale: [],
    lined: [],
    weight: [],
    plaidType: [],
    printType: [],
    gender: [],
    trend: [],
    gift: []
  },
  filteredResults: {
    products: [],
    meta: {
      page: 1,
      totalPages: 0,
      totalResults: 0
    }
  }
};
const reducer = (
  state: SearchResultsState = initialState,
  action: SearchResultsActionState
) => {
  const hasResults = (results) => {
    return Boolean(
      [
        ...results.products,
        ...results.pages,
        ...results.brands,
        ...results.journalPosts
      ].length
    );
  };

  switch (action.type) {
    case 'SEARCH_RESULTS/FETCH':
      return {
        ...state,
        filtersActive: action.fetchMore
          ? state.filtersActive
          : { ...initialState.filtersActive },
        filtersAreApplied: action.fetchMore ? state.filtersAreApplied : false,
        loadingResults: !action.fetchMore && true,
        loadingMoreProducts: action.fetchMore || false
      };
    case 'SEARCH_RESULTS/FETCH_SUCCESS':
      return {
        ...state,
        ...action.results,
        hasResults: hasResults(action.results),
        loadingResults: false
      };
    case 'SEARCH_RESULTS/FETCH_MORE_PRODUCTS_SUCCESS':
      return {
        ...state,
        meta: action.results.meta,
        products: [...state.products, ...action.results.products],
        loadingMoreProducts: false
      };
    case 'SEARCH_RESULTS/FETCH_MORE_FILTERED_PRODUCTS_SUCCESS':
      return {
        ...state,
        filteredResults: {
          meta: action.results.meta,
          products: [
            ...state.filteredResults.products,
            ...action.results.products
          ]
        },
        loadingMoreProducts: false
      };
    case 'SEARCH_RESULTS/TOGGLE_FILTER':
      return {
        ...state,
        loadingResults: true,
        filtersAreApplied: true,
        filtersActive: sortFilters(
          toggleFilter(
            state.filtersActive,
            action.payload.type,
            action.payload.value
          )
        )
      };
    case 'SEARCH_RESULTS/SET_FILTERS':
      return {
        ...state,
        loadingResults: true,
        filtersAreApplied: true,
        filtersActive: sortFilters({
          ...state?.filtersActive,
          ...action.filters
        })
      };
    case 'SEARCH_RESULTS/FILTER_SUCCESS':
      return {
        ...state,
        filteredResults: action.results,
        loadingResults: false
      };
    case 'SEARCH_RESULTS/CLEAR_FILTERS':
      return {
        ...state,
        loadingResults: false,
        filtersAreApplied: false,
        filtersActive: initialState.filtersActive,
        filteredResults: {}
      };
    default:
      return state;
  }
};

export default reducer;
