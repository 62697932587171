import { ProductTilePayloadType } from 'types/products';
/* Currently unused */
/* import { RelatedProductType } from 'modules/product/types'; */
import { CartAddonAlgorithmTypes } from 'modules/cart-addons';

const getCartAddonProducts = (algorithm: CartAddonAlgorithmTypes) => ({
  type: 'CART_ADDONS/GET_CART_ADDON_PRODUCTS',
  algorithm
});

const getCartAddonProductsSuccess = (
  addonProducts: ProductTilePayloadType[],
  algorithm: CartAddonAlgorithmTypes
) => ({
  type: 'CART_ADDONS/GET_CART_ADDON_PRODUCTS_SUCCESS',
  addonProducts,
  algorithm
});

const getCartAddonProductsFailure = (err: string) => ({
  type: 'CART_ADDONS/GET_CART_ADDON_PRODUCTS_FAILURE',
  error: err
});

/* Currently unused */
/*
 const getGoogleRecommendations = () => ({
   type: 'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS'
 });

 const getGoogleRecommendationsSuccess = (
   googleRecommendedProducts: (ProductTilePayloadType | RelatedProductType)[],
   algorithm: CartAddonAlgorithmTypes
 ) => ({
   type: 'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS_SUCCESS',
   googleRecommendedProducts,
   algorithm
 });

 const getGoogleRecommendationsFailure = (err: string) => ({
   type: 'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS_FAILURE',
   error: err
 });
*/

export {
  getCartAddonProducts,
  getCartAddonProductsSuccess,
  getCartAddonProductsFailure
  /* Currently unused */
  /*
  getGoogleRecommendations,
  getGoogleRecommendationsSuccess,
  getGoogleRecommendationsFailure
  */
};
