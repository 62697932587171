import * as actionCreators from './action-creators';
import reducer from './reducer';

export const { close, open } = actionCreators;
export default reducer;

export type CartWidgetActionState = OpenCartAction | CloseCartAction;

export type CartWidgetState = {
  isOpen: boolean;
};

interface OpenCartAction {
  type: 'CART_WIDGET/OPEN';
}

interface CloseCartAction {
  type: 'CART_WIDGET/CLOSE';
}
