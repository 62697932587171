import api from 'utils/huckberryApi';

import snakeCase from 'lodash/snakeCase';

/* Currently unused */
/* import qs from 'qs'; */

export const fetchCartAddons = (algorithm: 'default' | 'forYou') =>
  api.get('/api/cart_addons', {
    params: { cart_add_on_type: snakeCase(algorithm) }
  });

/* Currently unused */
/*
 export const fetchGoogleRecommendations = ({
   algorithm = 'recommended_pskus',
   productGroupIds
 }: {
   algorithm: string;
   productGroupIds: number[];
 }) =>
   api.get('/api/product_recommendations', {
     params: {
       algo: algorithm,
       product_group_id: productGroupIds
     },
     paramsSerializer: (params) =>
       qs.stringify(params, { arrayFormat: 'brackets' })
   });
*/
