import { SagaIterator } from 'redux-saga';
import { call, takeEvery, put, select } from 'redux-saga/effects';

import {
  getCartAddonProductsSuccess,
  getCartAddonProductsFailure
} from './action-creators';
import { fetchCartAddons } from './requests';
import { FetchCartAddonProductsType, CartAddonType } from 'modules/cart-addons';
import { ApplicationState } from 'types/rootState';

/* Currently unused */
/*
import { CartItem } from 'modules/cart';
import { ProductTilePayloadType } from 'types/products';
import { RelatedProductType } from 'modules/product/types';
*/

function* cartAddonsSaga(): SagaIterator {
  yield takeEvery('CART_ADDONS/GET_CART_ADDON_PRODUCTS', getCartAddonProducts);
  /* Currently unused */
  /*
  yield takeEvery(
    'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS',
    getGoogleRecommendations
  );
  */
}

function* getCartAddonProducts(
  action: FetchCartAddonProductsType
): SagaIterator {
  const cartAddons = yield select(
    (state: ApplicationState) => state.cartAddons
  );

  const targetAddon: CartAddonType = cartAddons[action.algorithm];
  // Check if we need to fetch the products
  const shouldFetchProducts =
    targetAddon.type === 'product-slider' && targetAddon.products.length === 0;

  if (shouldFetchProducts) {
    try {
      const { data } = yield call(fetchCartAddons, action.algorithm);
      yield put(getCartAddonProductsSuccess(data, action.algorithm));
    } catch (err) {
      return getCartAddonProductsFailure(
        `Error fetching ${action.algorithm} products`
      );
    }
  }
}

/* Currently unused */
/*
const filterProducts = (
  products: ProductTilePayloadType[] | RelatedProductType[],
  allCartSkus: string[]
) => {
  if (isEmpty(products)) {
    return [];
  }
  // Filter out products that are already in the cart
   return products.filter((product) => !allCartSkus.includes(product.sku));
 };

 function* getGoogleRecommendations(): SagaIterator {
   const cartItems = yield select((state: ApplicationState) => state.cart.items);
   if (isEmpty(cartItems)) {
     return;
   }

   const productGroupIds = cartItems.map(
     (item: CartItem) => item.product.productGroupId
   );
   const allCartSkus = cartItems.map(
     (item: CartItem) => item.product.productGroupId
   );

   try {
     const googleRecommendedProducts = yield call(fetchGoogleRecommendations, {
       algorithm: 'google_recommended_addons',
       productGroupIds: productGroupIds.join(',')
     });

     const filteredRecommendedProducts = filterProducts(
       googleRecommendedProducts.data,
       allCartSkus
     );

     if (isEmpty(filteredRecommendedProducts)) {
       return;
     }
     yield put(getGoogleRecommendationsSuccess(filteredRecommendedProducts));
   } catch {
     return getGoogleRecommendationsFailure(
       'Error fetching Google recommendations'
     );
   }
 }
*/

export default cartAddonsSaga;
